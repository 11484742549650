import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Process from "../components/process"
import Whitepaper from "../components/whitepaper"
import PaperList from "../components/paper-list"
import ReadList from "../components/read-list"
import HeaderResearch from "../components/header-research"
import FooterNav from "../components/footer-nav"

const ResearchPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeaderResearch />
    <SEO
     title="Research | MASS Blockchain Consensus Engine" keywords={[`MASS Blockchain Consensus Engine`]}
      lang={locale}
    />
    <Process />
    <Whitepaper />
    <PaperList />
    <FooterNav />
  </Layout>
)

ResearchPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default ResearchPage
