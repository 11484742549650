import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./paper-list.css"

const PaperList = () => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              id
              fluid {
                src
                srcSet
                aspectRatio
                sizes
                originalName
              }
            }
          }
        }
        allPapersJson {
          edges {
            node {
              id
              title
              cover_image
              download_url
            }
          }
        }
      }
    `}
    render={data => (
      <Fragment>
        <div className="paper-list-background">
          <div className="container">
            <h1 className="paper-title">
              <span>
                <FormattedMessage id="org.massnet.components.research.paper.title" />
              </span>
            </h1>
            <div className="paper-list-area">
              {data.allPapersJson.edges.map(({ node }) => (
                <a
                  className="paper-detail"
                  key={node.id}
                  href={node.download_url}
                  download=""
                  target="_blank"
                >
                  <div className="paper-list-image">
                    <Img
                      fluid={
                        data.allImageSharp.edges.find(
                          item =>
                            item.node.fluid.originalName === node.cover_image
                        ).node.fluid
                      }
                    />
                  </div>
                  <p className="paper-detail-title">
                    <FormattedMessage id={node.title} />
                  </p>
                  <div className="paper-download">
                    <a href={node.download_url} download="" target="_blank">
                      <FormattedMessage id="org.massnet.components.research.paper.download" />
                    </a>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </Fragment>
    )}
  />
)

export default PaperList
