import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const LogoDark = () => (
  <StaticQuery
    query={graphql`
      query {
        logoImage: file(relativePath: { eq: "logo-dark.png" }) {
          childImageSharp {
            fixed(width: 85, height: 26) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fixed={data.logoImage.childImageSharp.fixed}
        style={{ verticalAlign: "middle" }}
      />
    )}
  />
)
export default LogoDark
