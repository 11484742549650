import React, { Component } from "react"
import { FormattedMessage } from "react-intl"
import { navigate, withPrefix } from "gatsby-link"
import LogoLight from "./logo-light"
import LogoDark from "./logo-dark"
import LocalizedLink from "./localized-link"

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"

import "./navbar.css"

export default class ReactNavbar extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
      scrolling: false,
      lang: "en",
    }
    this.mainMenuScroll = this.mainMenuScroll.bind(this)
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  setLang() {
    let lang = "en"
    if (typeof window !== "undefined") {
      let pathLang = window.location.pathname.split("/")[1]
      if (pathLang) {
        lang = pathLang || "en"
      }
    }
    this.setState({
      lang: lang,
    })
  }
  mainMenuScroll() {
    if (window.scrollY >= 40) {
      if (this.state.scrolling !== true) {
        this.setState({
          scrolling: true,
        })
      }
    } else if (this.state.scrolling !== false) {
      this.setState({
        scrolling: false,
      })
    }
  }

  switchLang(lang) {
    if (typeof window !== "undefined") {
      const newUrl = withPrefix(
        window.location.pathname.replace(/\/(zh|en|ja|es|ru)/, lang)
      )
      window.localStorage.setItem("language", lang)
      navigate(newUrl)
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.mainMenuScroll, false)
    this.setLang()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.mainMenuScroll, false)
  }
  // componentDidUpdate(e1,e2){
  //   console.log("componentDidUpdate")
  //   console.log(e1)
  //   console.log(e2)
  //   console.log(this)
  //   if(e2.isOpen == this.state.isOpen){
  //     this.toggle()
  //   }
  // }

  render() {
    return (
      <Navbar
        color={
          this.state.scrolling || this.state.isOpen ? "white" : "transparent"
        }
        fixed="top"
        // expand="md"
      >
        <div className="navbar-content">
          <NavbarBrand>
            <LocalizedLink to="/">
              {this.state.scrolling || this.state.isOpen ? (
                <LogoDark />
              ) : (
                <LogoLight />
              )}
            </LocalizedLink>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  tag={() => (
                    <LocalizedLink
                      className="nav-link"
                      activeClassName="active"
                      to="/"
                    >
                      <FormattedMessage id="org.massnet.components.header.home" />
                    </LocalizedLink>
                  )}
                />
              </NavItem>
              <NavItem>
                <NavLink
                  tag={() => (
                    <a
                      className="nav-link"
                      href="https://wallet.massnet.org/"
                    >
                      <FormattedMessage id="org.massnet.components.header.test_net" />
                    </a>
                  )}
                />
              </NavItem>
              <NavItem>
                <NavLink
                  tag={() => (
                    <LocalizedLink
                      className="nav-link"
                      activeClassName="active"
                      to="/research"
                    >
                      <FormattedMessage id="org.massnet.components.header.research" />
                    </LocalizedLink>
                  )}
                />
              </NavItem>
              <NavItem>
                <NavLink
                  tag={() => (
                    <LocalizedLink
                      className="nav-link"
                      activeClassName="active"
                      to="/develop"
                    >
                      <FormattedMessage id="org.massnet.components.header.history" />
                    </LocalizedLink>
                  )}
                />
              </NavItem>
              <NavItem>
                <NavLink
                  tag={() => (
                    <LocalizedLink
                      className="nav-link"
                      activeClassName="active"
                      to="/download"
                    >
                      <FormattedMessage id="org.massnet.components.header.download" />
                    </LocalizedLink>
                  )}
                />
              </NavItem>
              <NavItem>
                <NavLink
                  tag={() => (
                    <LocalizedLink
                      className="nav-link"
                      activeClassName="active"
                      to="/about"
                    >
                      <FormattedMessage id="org.massnet.components.header.about" />
                    </LocalizedLink>
                  )}
                />
              </NavItem>
              <NavItem>
                <NavLink
                  tag={() => (
                    <LocalizedLink
                      className="nav-link"
                      activeClassName="active"
                      to="/blog"
                    >
                      <FormattedMessage id="org.massnet.components.header.blog" />
                    </LocalizedLink>
                  )}
                />
              </NavItem>
              <NavItem className="lang-item">
                <a
                  className={
                    "lang-link " + (this.state.lang === "en" ? "active" : "")
                  }
                  onClick={e => {
                    e.preventDefault()
                    this.switchLang("en")
                  }}
                  href="#"
                >
                  EN
                </a>
                /
                <a
                  className={
                    "lang-link " + (this.state.lang === "zh" ? "active" : "")
                  }
                  onClick={e => {
                    e.preventDefault()
                    this.switchLang("zh")
                  }}
                  href="#"
                >
                  中文
                </a>
                /
                <a
                  className={
                    "lang-link " + (this.state.lang === "ja" ? "active" : "")
                  }
                  onClick={e => {
                    e.preventDefault()
                    this.switchLang("ja")
                  }}
                  href="#"
                >
                  日本語
                </a>
                /
                <a
                  className={
                    "lang-link " + (this.state.lang === "es" ? "active" : "")
                  }
                  onClick={e => {
                    e.preventDefault()
                    this.switchLang("es")
                  }}
                  href="#"
                >
                  Español
                </a>
                /
                <a
                  className={
                    "lang-link " + (this.state.lang === "ru" ? "active" : "")
                  }
                  onClick={e => {
                    e.preventDefault()
                    this.switchLang("ru")
                  }}
                  href="#"
                >
                  русский
                </a>
              </NavItem>
              
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    )
  }
}
