import React from "react"
import { FormattedMessage } from "react-intl"

import LocalizedLink from "./localized-link"

import {
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"

import "./footer-nav.css"

const FooterNav = () => (
  <Nav className="footer-nav" navbar>
    <NavItem>
      <NavLink
        tag={() => (
          <LocalizedLink
            className="nav-link"
            activeClassName="active"
            to="/"
          >
            <FormattedMessage id="org.massnet.components.header.home" />
          </LocalizedLink>
        )}
      />
    </NavItem>
    <NavItem>
      <NavLink
        tag={() => (
          <LocalizedLink
            className="nav-link"
            activeClassName="active"
            to="/research"
          >
            <FormattedMessage id="org.massnet.components.header.research" />
          </LocalizedLink>
        )}
      />
    </NavItem>
    <NavItem>
      <NavLink
        tag={() => (
          <LocalizedLink
            className="nav-link"
            activeClassName="active"
            to="/develop"
          >
            <FormattedMessage id="org.massnet.components.header.history" />
          </LocalizedLink>
        )}
      />
    </NavItem>
    <NavItem>
      <NavLink
        tag={() => (
          <LocalizedLink
            className="nav-link"
            activeClassName="active"
            to="/download"
          >
            <FormattedMessage id="org.massnet.components.header.download" />
          </LocalizedLink>
        )}
      />
    </NavItem>
    <NavItem>
      <NavLink
        tag={() => (
          <LocalizedLink
            className="nav-link"
            activeClassName="active"
            to="/about"
          >
            <FormattedMessage id="org.massnet.components.header.about" />
          </LocalizedLink>
        )}
      />
    </NavItem>
  </Nav>
)

export default FooterNav

